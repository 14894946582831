import { getCookie } from "h3"

// This middleware gets dynamically added to all /members routes in nuxt.config.ts

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) {
    if (!getCookie(useRequestEvent(), "i")) {
      return navigateTo("/login")
    }
  }
})
